import React from 'react';

import { IRenderWidgetProps } from '../../../assets/modules/renderWidget/model';
import { getWidgetInitialiser } from '../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../utils/loadCSS';
import { getInitialiser } from '../../modules/getInitialiser';
import type { DealData } from '../../types/DealData';
import { GetInitialiserProps, WidgetWrapper } from '../../types/GetInitialiserProps';
import { RequestType, getJSONFn } from '../../types/GetJSON';
import { WidgetInitialiserOptions } from '../../types/WidgetInitialiser';
import type { WidgetProps } from '../../types/WidgetProps';
import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import DealOfTheWeekItems from './DealOfTheWeekItems';
import DealOfTheWeekToggle from './DealOfTheWeekToggle';
import styles from './styles/deal-of-the-week.css';

loadCSS('dotw');

interface DealOfTheWeekProps {
  endpoint: WidgetProps['endpoint'];
  getDealData: WidgetProps['getDealData'];
  dealData: WidgetProps['dealData'];
  getJSON: WidgetProps['getJSON'];
  sendAnalytics: WidgetProps['sendAnalytics'];
  setDealData: WidgetProps['setDealData'];
  translate: WidgetProps['translate'];
  widgetEndpoint: WidgetProps['widgetEndpoint'];
  genericSharedComponents: WidgetProps['genericSharedComponents'];
}

const DealOfTheWeek: React.FC<DealOfTheWeekProps> = (props) => {
  const {
    genericSharedComponents: { HawkWidgetAdvanced, Main, Spinner, Title },
  } = props;
  return (
    <HawkWidgetAdvanced {...props}>
      <Consumer>
        {(value): JSX.Element => {
          // TODO: possibly remove default value of {} for dealData
          const { dealData = {} as DealData, activeTab, loading, widgetTitle, translate } = value;

          return (
            <Main
              {...{
                ...props,
                className: 'dotw',
              }}
            >
              {loading && <Spinner />}
              <div className={styles.header}>
                <Title
                  {...{
                    title: widgetTitle || translate('dotwHeaderHeading'),
                    className: 'dotw',
                  }}
                />
              </div>
              <DealOfTheWeekItems />
              {dealData.totalDeals > activeTab?.pageSize ? <DealOfTheWeekToggle /> : null}
            </Main>
          );
        }}
      </Consumer>
    </HawkWidgetAdvanced>
  );
};

DealOfTheWeek.getInitialiserProps = async (
  { el, site, articleUrl, ...restRenderProps }: IRenderWidgetProps,
  attributes: DOMStringMap,
  getJSON: getJSONFn<RequestType.WIDGET_REQUEST>,
  editorial: boolean,
  defaultParams: WidgetInitialiserOptions['defaultParams'],
  endpoint: string,
  url: string,
  origin: string,
  dataLinkMerchant: string | null,
): GetInitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type, apiCall } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    endpoint,
    url,
    origin,
    dataLinkMerchant,
    site,
    getJSON,
    placeholder: el,
    articleUrl,
    attributes,
    getInitialiser,
    ...restRenderProps,
  });

  return { props, type, apiCall, widgetWrapper: WidgetWrapper.DIV };
};

export default DealOfTheWeek;
