import React from 'react';

import type { DealData } from '../../types/DealData';
import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import styles from './styles/deal-of-the-week-toggle.css';
import { toggleLessOrMoreDeals } from './toggleLessOrMoreDeals';

const DealOfTheWeekToggle: React.FC = () => (
  <Consumer>
    {(value): JSX.Element => {
      // TODO: possibly remove default value of {} for dealData
      const { dealData = {} as DealData, translate } = value;
      const allDealsVisible = dealData.totalDeals === (dealData.deals || []).length;
      const containerStyle = allDealsVisible ? 'less-container' : 'container';

      return (
        <div
          className={styles[containerStyle]}
          onClick={toggleLessOrMoreDeals}
          role="button"
          tabIndex={0}
          onKeyDown={toggleLessOrMoreDeals}
        >
          <span className={styles.label}>
            {translate(allDealsVisible ? 'dotwToggleLess' : 'dotwToggleMore')}
          </span>
        </div>
      );
    }}
  </Consumer>
);
export default DealOfTheWeekToggle;
