import { useContext } from 'react';

import { Context } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

export const toggleLessOrMoreDeals = (): void => {
  const { allDealsVisible, showFewerDeals, loadMoreDeals } = useContext(Context);

  if (allDealsVisible) {
    showFewerDeals();
  } else {
    loadMoreDeals(true);
  }
};
