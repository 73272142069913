import React from 'react';

import type { Deal } from '../../types/Deal';
import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import styles from './styles/deal-of-the-week-item-merchant.css';

interface DealOfTheWeekItemMerchantProps {
  deal: Deal;
}

const DealOfTheWeekItemMerchant: React.FC<DealOfTheWeekItemMerchantProps> = ({
  deal = {} as Deal,
}) => {
  return (
    <Consumer>
      {(value): JSX.Element => {
        const {
          translate,
          genericSharedComponents: { DealImage },
        } = value;
        const saving =
          deal?.offer?.was_price &&
          deal?.offer?.price &&
          parseFloat(
            (parseFloat(String(deal.offer.was_price)) - parseFloat(deal.offer.price)).toFixed(2),
          );
        const percentage =
          saving && ((saving / parseFloat(String(deal.offer.was_price))) * 100).toFixed(0);

        return (
          <div className={styles.container}>
            {deal?.offer?.was_price && (
              <div className={styles.text}>
                <span>{translate('dotwItemMainDiscountLabel')} </span>
                <span className={styles.bold}>{percentage}%</span>
              </div>
            )}
            <DealImage
              {...{
                deal,
                priority: ['merchant'],
              }}
            />
          </div>
        );
      }}
    </Consumer>
  );
};

export default DealOfTheWeekItemMerchant;
