import React from 'react';

import { useHawkWidgetContext } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import DealOfTheWeekItemMerchant from './DealOfTheWeekItemMerchant';
import styles from './styles/deal-of-the-week-items.css';

const DealOfTheWeekItems: React.FC = () => {
  const {
    genericSharedComponents: { Button, GridItems, DealImage, DisplayName, MainPrice },
  } = useHawkWidgetContext();

  return (
    <GridItems
      className={{
        gridItemMain: styles['grid-item-main'],
        gridItemBlock: styles['grid-item-block'],
        gridItem: styles['grid-item'],
      }}
      stacked={true}
      widgetName="dotw"
    >
      {(deal): JSX.Element => (
        <>
          <DisplayName key="name" deal={deal} className={styles['display-name']} />
          <DealImage key="image" deal={deal} />
          <DealOfTheWeekItemMerchant key="merchant" deal={deal} />
          <React.Fragment key="priceLink">
            <MainPrice deal={deal} className="above" />
            <Button deal={deal} className="dotw" />
          </React.Fragment>
        </>
      )}
    </GridItems>
  );
};

export default DealOfTheWeekItems;
